import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, FormControl, Grid, IconButton, InputAdornment, Link, TextField, makeStyles } from '@material-ui/core'
import { Trans, t } from '@lingui/macro'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ReCAPTCHA from 'react-google-recaptcha'
import { LoginModel } from '../../api/api'
import { KEY_PUBLIC_CAPTCHA } from '../../config'
import { LoginFormProps } from './SignSideForm'
import SelectEntityForm from './SelectEntityForm'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: '230px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  submitRecovery: {
    margin: theme.spacing(3, 1, 2)
  },
  outlineInputLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: theme.palette.error.contrastText
  },
  fullWidth: {
    width: '100%'
  },
  typography: {
    padding: theme.spacing(2)
  },
  newsFrame: {
    height: '100vh',
    width: '100%',
    border: 0
  },
  captcha: {
    marginRight: 'auto',
    marginLeft: 'auto'
  }
}))

export const LoginForm: React.FC<LoginFormProps> = props => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [login, setLogin] = useState({ username: '', password: '' } as LoginModel)
  const [useCaptcha, setUseCaptcha] = React.useState(false)
  const [verificCaptcha, setVerificCaptcha] = React.useState(false)
  const validLoginRegex = /^\s+|[^\w a-zA-Z0-9_'. @\u00f1\u00d1\u00C0-\u017F]{0,100}/g
  const recaptcha = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    setUseCaptcha(KEY_PUBLIC_CAPTCHA !== '')
  }, [])

  useEffect(() => {
    if (!props.isValidAuth) {
      setVerificCaptcha(false)
      setLogin({ username: '', password: '' })
    }
  }, [props.isValidAuth])

  const handleUserName = (event: ChangeEvent<{ value: unknown }>) => {
    const usernameInputValue = (event.target.value as string).replace(validLoginRegex, '')
    setLogin({ ...login, username: usernameInputValue })
  }

  const handleInputChange = (event: ChangeEvent<{ value: string }>) => {
    const newlogin = { ...login, password: event.target.value }
    setLogin({ ...newlogin })
  }

  const handleLogin = () => {
    setShowPassword(false)
    props.login.postLogin(login)
  }

  return (
    <>
      <form className={classes.form} noValidate={true}>
        <TextField
          variant='outlined'
          margin='normal'
          required={true}
          fullWidth={true}
          id='username'
          label={t`Usuario`}
          name='username'
          autoComplete='user'
          autoFocus={true}
          value={login.username}
          onChange={handleUserName}
          disabled={props.isAuthenticated}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required={true}
          fullWidth={true}
          name='password'
          label={t`Contraseña`}
          type={showPassword ? 'text' : 'password'}
          id='password'
          autoComplete='current-password'
          value={login.password}
          onChange={handleInputChange}
          disabled={props.isAuthenticated}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  data-testid='toggle-password-visibility'
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Grid container={true} alignItems='flex-start' justifyContent='flex-end' direction='row'>
          <Link href='#' variant='body2' color='secondary' onClick={props.changeForm}>
            <Trans>Recuperar Contraseña</Trans>
          </Link>
        </Grid>
        {useCaptcha && login.username.trim() !== '' && login.password.trim() !== '' && !props.isAuthenticated && (
          <FormControl fullWidth={true} variant='outlined' style={{ marginTop: '25px' }}>
            <ReCAPTCHA
              sitekey={KEY_PUBLIC_CAPTCHA}
              ref={recaptcha}
              size='normal'
              onChange={() => {
                setVerificCaptcha(true)
              }}
              className={classes.captcha}
            />
          </FormControl>
        )}
        {!props.isValidAuth && (
          <Button
            id='valide-button'
            onClick={handleLogin}
            fullWidth={true}
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={
              (useCaptcha && !verificCaptcha) ||
              login.username.trim() === '' ||
              login.password.trim() === '' ||
              props.isAuthenticated
            }
          >
            <Trans>Validar</Trans>
          </Button>
        )}
        {props.isValidAuth && (
          <SelectEntityForm
            {...{
              isAuthenticated: props.isAuthenticated,
              postSelectCompany: props.login.postSelectCompany,
              entityServices: props.entityServices,
              handleCloseSession: props.handleCloseSession
            }}
          />
        )}
      </form>
    </>
  )
}
