import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { GetDataError } from 'restful-react'
import jwtDecode from 'jwt-decode'
import { APIError, usePostPingSession, usePutAuth, usePutToken } from '../../api/api'
import { clearUserSessionCookies, generateWindowNavTabID, getWindowNavTabID } from '../../libs/cookies'
import { ICustomBaseJwtPayload, useAPISecurity } from '../../security/APISecurityContext'

const internalServerError = 500
const defaultError = 0
const SinEntidad = 0

export const useCheckActiveSession = (setErrorMessage: (message: string) => void) => {
  const [isActiveSession, setIsActiveSession] = useState<null | boolean>(null)
  const pingSessionApi = usePostPingSession({})
  const { securityTokens, setSecurityTokens, setIsAuthenticated } = useAPISecurity()
  const refreshTokenApi = usePutToken({})
  const autoLoginApi = usePutAuth({})
  const [loading, setLoading] = useState(true)
  const [validSecurityTokens, setValidSecurityTokens] = useState<null | boolean>(null)
  const [autoLogin, setAutoLogin] = useState(false)

  const emptySecurityTokens = (accessToken?: string, refreshToken?: string) => {
    if (accessToken && accessToken !== '' && refreshToken && refreshToken !== '') {
      return false
    }

    return true
  }

  const reportErrorApi = (errorStatusCode: number) => {
    setIsActiveSession(false)
    if (errorStatusCode >= internalServerError) {
      setErrorMessage(t`Ocurrió un problema al intentar conectar con el servidor.`)
    }
  }

  useEffect(() => {
    if (emptySecurityTokens(securityTokens.accessToken, securityTokens.refreshToken)) {
      setIsActiveSession(false)
      return
    }

    const jwtRefresh = jwtDecode<ICustomBaseJwtPayload>(securityTokens?.refreshToken ?? '')

    if (Number(jwtRefresh.EntityId) === SinEntidad) {
      setIsActiveSession(false)
      setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
      return
    }

    refreshTokenApi
      .mutate({ refresh_token: securityTokens.refreshToken })
      .then(value => {
        setSecurityTokens({ ...securityTokens, accessToken: value.access_token, refreshToken: value.refresh_token })
        setValidSecurityTokens(true)
        if (getWindowNavTabID() === '') {
          setAutoLogin(true)
        }
      })
      .catch((error: GetDataError<void | APIError>) => {
        setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
        setLoading(false)
        reportErrorApi(error.status || defaultError)
        clearUserSessionCookies()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validSecurityTokens === null) {
      return
    }

    const appTabNavId = getWindowNavTabID()

    if (validSecurityTokens && appTabNavId !== '') {
      pingSessionApi
        .mutate({}, { queryParams: { iMedicalCloudAppTabNavId: appTabNavId } })
        .then(value => {
          setIsActiveSession(!value.d.ExpiredSession)
          setAutoLogin(value.d.ExpiredSession)
        })
        .catch(() => {
          setIsActiveSession(false)
          clearUserSessionCookies()
          setErrorMessage(t`Ocurrió un problema al intentar conectar con el servidor.`)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSecurityTokens])

  useEffect(() => {
    if (!autoLogin) {
      return
    }

    // Autologin
    generateWindowNavTabID()

    autoLoginApi
      .mutate()
      .then(() => {
        setIsActiveSession(true)
      })
      .catch((error: GetDataError<void | APIError>) => {
        reportErrorApi(error.status || defaultError)
        clearUserSessionCookies()
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLogin])

  useEffect(() => {
    if (isActiveSession === null) {
      return
    }

    setLoading(false)

    if (!isActiveSession) {
      setIsAuthenticated(false)
      generateWindowNavTabID()
      return
    }

    setIsAuthenticated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveSession])

  return loading
}
