import jwtDecode, { JwtPayload } from 'jwt-decode'
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import useSessionStorage from 'react-use/lib/useSessionStorage'
import { clearUserSessionCookies } from '../libs/cookies'

export const securityTokensLocalStorageKey = 'securityTokens'

export interface SecurityTokens {
  accessToken?: string
  refreshToken?: string
  timeDifference?: number
}

export interface ICustomBaseJwtPayload extends JwtPayload {
  EntityId: number
}
export interface ICustomJwtPayload extends JwtPayload {
  ind2FA: boolean
  indConfig2FA: string
  EntityId: number
}

interface APISecurityContextProps {
  securityTokens: SecurityTokens
  setSecurityTokens(securityTokens: SecurityTokens): void
  isAuthenticated: boolean
  setIsAuthenticated(isAuthenticated: boolean): void
  isUse2FA: boolean
  setIsUse2FA: (value: boolean) => void
  config2FA: string
  setConfig2FA: (value: string) => void
  isValidAuth: boolean
  setIsValidAuth: (value: boolean) => void
  handleCloseSession: () => void
}

const initialSecurityTokens: SecurityTokens = { accessToken: '', refreshToken: '', timeDifference: 0 }

export const APISecurityContext = createContext<APISecurityContextProps>({
  securityTokens: initialSecurityTokens,
  setSecurityTokens: () => {
    return
  },
  isAuthenticated: false,
  setIsAuthenticated: () => {
    return
  },
  isUse2FA: false,
  setIsUse2FA: () => {
    return
  },
  config2FA: '',
  setConfig2FA: () => {
    return
  },
  isValidAuth: false,
  setIsValidAuth: () => {
    return
  },
  handleCloseSession: () => {
    return
  }
})

interface Props {
  children?: ReactNode
}

export const APISecurityProvider: FC<Props> = ({ children }) => {
  const [securityTokens, setSecurityTokens] = useSessionStorage(securityTokensLocalStorageKey, initialSecurityTokens)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isValidAuth, setIsValidAuth] = useState(false)
  const [isUse2FA, setIsUse2FA] = useState(false)
  const [config2FA, setConfig2FA] = useState('')

  const setIsAuthenticatedCustom = (value: boolean) => {
    if (!value) {
      clearUserSessionCookies()
    }
    setIsAuthenticated(value)
  }

  const handleCloseSession = () => {
    setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
    setIsAuthenticated(false)
    setIsValidAuth(false)
    window.location.reload()
  }

  useEffect(() => {
    if (
      (isValidAuth || isAuthenticated) &&
      securityTokens.accessToken &&
      securityTokens.accessToken !== '' &&
      securityTokens.refreshToken &&
      securityTokens.refreshToken !== ''
    ) {
      const jwt = jwtDecode<ICustomJwtPayload>(securityTokens.accessToken)
      setIsUse2FA(String(jwt?.ind2FA ?? 'False') !== 'False')
      setConfig2FA(jwt?.indConfig2FA ?? '')
    }
  }, [isValidAuth, isAuthenticated, securityTokens])

  const ctxValue: APISecurityContextProps = {
    securityTokens,
    setSecurityTokens,
    isAuthenticated,
    setIsAuthenticated: setIsAuthenticatedCustom,
    isValidAuth,
    setIsValidAuth,
    isUse2FA,
    setIsUse2FA,
    config2FA,
    setConfig2FA,
    handleCloseSession
  }

  return <APISecurityContext.Provider value={ctxValue}>{children}</APISecurityContext.Provider>
}

export const useAPISecurity = () => useContext(APISecurityContext)
