import { t, Trans } from '@lingui/macro'
import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useIdle } from 'react-use'
import { useGetAuthInfo, useGetAuthNotifications, usePostGetMenu, usePostPingSession } from '../../api/api'
import MedicalSchedulerContainer from '../../functionality/appointments/doctoragenda/MedicalSchedulerContainer'
import { clearUserSessionCookies, getWindowNavTabID } from '../../libs/cookies'
import useInterval from '../../libs/useInterval'
import MenuItem from '../../models/MenuItem'
import Notification from '../../models/Notification'
import MainPage from '../../pages/main/MainPage'
import { useAPISecurity } from '../../security/APISecurityContext'
import useAutoRefreshTokens from '../../security/useAutoRefreshTokens'
import { useMenuItems } from './contexts/useMenuItems'
import FrameContent from './FrameContent'
import NotFound, { noMatch } from './NotFound'
import MainAppBar from './MainAppBar'
import MainNavigation from './MainNavigation'
import MenuPanel from './MenuPanel'
import NotificationsSnackbar from './NotificationsSnackbar'
import SimpleDialog from './SimpleDialog'
import { UseSignalRNotification } from './UseSignalRNotification'

const heightMainAppBar = 7.18
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.error.contrastText
    },
    separator: {
      display: 'flex',
      minHeight: theme.spacing(heightMainAppBar)
    }
  })
)

const EveryTenMinutes = 600000
const EveryMinute = 60000
const TwoMinute = 120000
const MinNoPingTime = 1
const MaxNoPingTime = 4
const menuWindowTitle = t`Menú - iMedical`
export const menuRootPath = '/main/menu'
const doctorAgendaPath = 'Prestacion/Citas/MedicosAgenda.aspx'

const getMenuItemContainsPath = (menuItems: MenuItem[], path: string): MenuItem | null => {
  for (const menuItem of menuItems) {
    if (menuItem.url.toLowerCase().includes(path.toLowerCase())) {
      return menuItem
    }

    if (menuItem.items && menuItem.items.length > 0) {
      const found = getMenuItemContainsPath(menuItem.items, path)
      if (found) {
        return found
      }
    }
  }

  return null
}

function MedicalScheduler() {
  const { menuItems, setSelectedMenuItem } = useMenuItems()
  const [menuItemAllowed] = useState(getMenuItemContainsPath(menuItems, doctorAgendaPath))

  useEffect(() => {
    if (menuItemAllowed) {
      setSelectedMenuItem(menuItemAllowed)
    }
  }, [])

  return <>{menuItemAllowed && <MedicalSchedulerContainer />}</>
}

const isLazzyPingSession = () => {
  const hourCurrentLocalTime = new Date().getHours()
  return hourCurrentLocalTime >= MinNoPingTime && hourCurrentLocalTime <= MaxNoPingTime
}
// eslint-disable-next-line max-lines-per-function
const MainMenu: FunctionComponent = () => {
  const classes = useStyles()
  const { setMenuItems, selectedMenuItem, setSelectedMenuItem } = useMenuItems()
  const [open, setOpen] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [errorLoadingData, setErrorLoadingData] = useState(false)
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const { handleCloseSession, isAuthenticated } = useAPISecurity()

  const { NotificationSignalR, callNotification } = UseSignalRNotification()
  const pingSession = usePostPingSession({})
  const authInfo = useGetAuthInfo({ lazy: !isAuthenticated })
  const menuInfo = usePostGetMenu({})
  const getNotifications = useGetAuthNotifications({})
  const idletimeOut = authInfo.data?.userInfo.idleTimeout ?? EveryTenMinutes
  const errorAutoRefreshTokens = useAutoRefreshTokens(EveryMinute, idletimeOut)

  const idle = useIdle(idletimeOut - TwoMinute)
  useHotkeys('ctrl+b', event => {
    setOpen(true)
    event.preventDefault()
  })

  useEffect(() => {
    setIsLoadingData(authInfo.loading || menuInfo.loading || getNotifications.loading)
  }, [authInfo.loading, menuInfo.loading, getNotifications.loading])

  useEffect(() => {
    setErrorLoadingData(authInfo.error !== null || menuInfo.error !== null || errorAutoRefreshTokens)
  }, [authInfo.error, menuInfo.error, errorAutoRefreshTokens])

  useEffect(() => {
    if (errorAutoRefreshTokens) {
      clearUserSessionCookies()
      handleCloseSession()
    }
  }, [errorAutoRefreshTokens])

  useInterval(async () => {
    if (getWindowNavTabID() !== '' && !isLazzyPingSession()) {
      const response = await pingSession.mutate({}, { queryParams: { iMedicalCloudAppTabNavId: getWindowNavTabID() } })

      if (response.d.ExpiredSession) {
        setErrorLoadingData(true)
      }
    }
  }, EveryTenMinutes)

  useEffect(() => {
    if (!isLoadingData && !errorLoadingData && history.location.pathname === menuRootPath) {
      setOpen(true)
    }
  }, [isLoadingData, errorLoadingData, history.location.pathname])

  useEffect(() => {
    if (!getNotifications.loading && getNotifications.data && getNotifications.data.length > 0) {
      const notificationItems: Notification[] = []
      for (let index = 0; index < getNotifications.data.length; index++) {
        const notificationResponse = getNotifications.data[index]
        notificationItems.push({
          checked: false,
          helpText: notificationResponse.message,
          title: notificationResponse.title,
          type: notificationResponse.type,
          id: index
        })
      }
      setNotifications(notificationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotifications.loading])

  useEffect(() => {
    if (NotificationSignalR) {
      NotificationSignalR.id = notifications.length
      setNotifications([...notifications, NotificationSignalR])
    }
  }, [NotificationSignalR])

  useEffect(() => {
    callNotification()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!selectedMenuItem) {
      document.title = menuWindowTitle
      setMenuItems([])
      void menuInfo.mutate({}).then(value => {
        if (value && value.d && value.d.length > 0) {
          setMenuItems(value.d)
        }
      })

      void authInfo.refetch()

      return
    }

    document.title = selectedMenuItem.title
    setOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem])

  useEffect(() => {
    document.title = menuWindowTitle
  }, [])

  useEffect(() => {
    return history.listen(location => {
      if (location.pathname === path) {
        setSelectedMenuItem(null)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleCloseErrorDialog = () => {
    handleCloseSession()
  }

  const getSubtitle = () => {
    if (
      !authInfo.data?.userInfo ||
      !authInfo.data?.userInfo.environment ||
      authInfo.data?.userInfo.environment === ''
    ) {
      return ''
    }

    return authInfo.data?.userInfo.version + ' ' + authInfo.data?.userInfo.database
  }

  return (
    <div className={classes.root}>
      <Backdrop id='main-backdrop-load' open={isLoadingData} className={classes.backdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <SimpleDialog
        open={errorLoadingData}
        title={'iMedical'}
        contentText={t`¡Su sesión Expiró o esta intentando abrir otra pestaña del navegador! La aplicación le pedirá nuevamente sus datos de inicio.`}
        dialogActions={
          <Button onClick={handleCloseErrorDialog} color='primary'>
            <Trans>Aceptar</Trans>
          </Button>
        }
        dialogClose={handleCloseErrorDialog}
      />
      <SimpleDialog
        open={idle}
        title={'iMedical'}
        contentText={t`¡Su sesión Expirará en 2 minutos si no se detecta actividad!`}
        dialogActions={
          <Button
            onClick={() => {
              return
            }}
            color='primary'
          >
            <Trans>Aceptar</Trans>
          </Button>
        }
        dialogClose={() => {
          return
        }}
      />
      <MainAppBar
        open={open && !isLoadingData}
        setOpen={setOpen}
        subtitle={getSubtitle()}
        shortNameCompany={authInfo.data?.userInfo.shortCompanyName}
        branchOffice={authInfo.data?.userInfo.branchOffice}
        notifications={notifications}
      />
      <MainNavigation
        open={open && !isLoadingData}
        setOpen={setOpen}
        onMouseLeave={handleDrawerClose}
        userInfo={authInfo.data?.userInfo}
      >
        <MenuPanel />
      </MainNavigation>
      {!isLoadingData && (
        <Grid container={true}>
          <Grid item={true} className={classes.separator} xs={12} />
          <Grid item={true} xs={12}>
            <Switch>
              <Route path={`${url}/option/:menuItemId`}>
                <FrameContent key={selectedMenuItem?.id} />
              </Route>
              <Route exact={false} path={`${url}/doctor/agenda`}>
                <MedicalScheduler />
              </Route>
              <Route exact={true} path={`${url}`}>
                <MainPage
                  publications={authInfo.data?.publications}
                  documents={authInfo.data?.documents}
                  userInfo={authInfo.data?.userInfo}
                />
              </Route>
              <Route path={noMatch}>
                <NotFound />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      )}
      {notifications && notifications.length > 0 && (
        <NotificationsSnackbar notifications={notifications.filter((n: Notification) => n.type === 'Important')} />
      )}
    </div>
  )
}

export default MainMenu
